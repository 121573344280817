<template>
  <div>
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      :max-width="dialogWidth"
      scrollable
    >
      <v-card v-if="item">
        <v-card-title class="pa-0">
          <v-toolbar
          >
            <v-btn
              icon
              @click="dialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ $t('app.general.manage') }} {{ $t('app.equipos.name') }}
              {{ $t('app.general.for_the_m') }} {{ $t('app.torneos.single') }}
              {{ this.item.nombre }}
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="(team, index) in item.equipos"
              :key="`equipo-${index}`"
            >
              <v-list-item-avatar>
                <v-avatar
                  tile
                  color="primary"
                >
                  <img :src="team.logotipo" alt="" v-if="team.logotipo">
                  <span class="white--text">{{ tokenizeName(team.nombre) }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ team.nombre }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="openConfirm(team)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-btn outlined block @click="dialogAdd = true">
                  {{ $t('app.buttons.add') }}
                  {{ $t('app.equipos.single') }}
                </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogAdd"
      transition="dialog-bottom-transition"
      :max-width="dialogWidth"
      scrollable
      persistent
    >
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar
          >
            <v-btn
              icon
              @click="dialogAdd = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ $t('app.buttons.add') }} {{ $t('app.equipos.single') }}
            </v-toolbar-title>
            <v-spacer />
            <v-btn
              rounded
              color="primary"
              @click="addTeam"
            >
              {{ $t('app.buttons.save') }}
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <validation-observer
            ref="observerAdd"
            v-slot="{ validate, reset }"
          >
            <form>
              <v-list
                three-line
              >
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>{{ $t('app.equipos.name') }}</v-list-item-title>
                    <v-list-item-subtitle>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('app.equipos.name')"
                        rules="required"
                      >
                        <v-autocomplete
                          v-model="newTeams"
                          :placeholder="$t('app.equipos.name')"
                          :items="teams"
                          item-text="nombre"
                          item-value="id"
                          :error-messages="errors"
                          required
                          multiple
                          chips
                          deletable-chips
                          clearable
                          :filter="autocompleteCustomFilter"
                        ></v-autocomplete>
                      </validation-provider>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogConfirm"
      persistent
      max-width="320"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('app.general.confirm_delete') }}
        </v-card-title>
        <v-card-actions>
          <v-btn
            color="green darken-1"
            text
            @click="dialogConfirm = false"
          >
            {{ $t('app.buttons.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="red dark"
            @click="removeTeam"
          >
            {{ $t('app.buttons.confirm') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import autocompleteCustomFilter from "../../../../plugins/customFilter";

export default {
  name: "TorneosManageTeams",
  mixins: [autocompleteCustomFilter],
  data () {
    return {
      dialog: false,
      dialogAdd: false,
      dialogConfirm: false,
      itemToDelete: false,
      item: null,
      teams: [],
      newTeams: []
    }
  },
  mounted() {
    EventBus.$on('torneos-gestionar-equipos', async item => {
      this.item = item

      this.toggleLoader()
      this.teams = await this.getEquipos()

      let existing = this.item.equipos.map(item => item.id)
      this.teams = this.teams.filter(item => item.sucursal_id === this.item.sucursal_id)
      this.teams = this.teams.filter(item => !existing.includes(item.id))
      this.toggleLoader()

      this.dialog = true
    })

    EventBus.$on('reload-tournament', async () => {
      await this.getTournament()
    })
  },
  methods: {
    async getTournament () {
      this.$http.get(route(`v1/tournament/${this.item.id}`))
      .then(response => {
        if (response.body.code === 200) {
          let data = response.body.data
          if (data.length > 0) this.item = data[0]
        } else {
          this.processError(response)
        }
      }, error => this.processError(error))
    },
    saveItem () {

    },
    addTeam () {
      this.$refs.observerAdd.validate()
      .then(async success => {
        if (!success) return

        this.toggleLoader()
        await this.$http.post(route('v1/tournament/add-teams'), {
          torneo_id: this.item.id,
          equipos: this.newTeams
        }).then(response => {
          if (response.body.code === 200) {
            EventBus.$emit('reload-tournament')
            requestAnimationFrame(() => {
              this.$refs.observerAdd.reset()
            })
            this.dialogAdd = false
          } else {
            this.processError(response)
          }
        }, error => this.processError(error))

        this.toggleLoader()
      })
    },
    openConfirm (item) {
      this.itemToDelete = item
      this.dialogConfirm = true
    },
    async removeTeam () {
      this.dialogConfirm = false
      this.toggleLoader()

      await this.$http.post(route('v1/tournament/remove-team'), {
        equipo_id: this.itemToDelete.pivot.id
      }).then(response => {
        if (response.body.code === 200) {
          EventBus.$emit('reload-tournament')
        } else {
          this.processError(response)
        }
      }, error => this.processError(error))

      this.toggleLoader()
    }
  }
}
</script>

<style scoped>

</style>
